<template>
  <div class="terms">
    <section>
      <h3>CareerBuddy Terms of Service</h3>
      <p>
        These Terms of Service govern your use of the website located at https://thecareerbuddy.com/ and any related services provided by CareerBuddy.
      </p>
      <p>
        By accessing https://thecareerbuddy.com/, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations.
        If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided
        by CareerBuddy.
      </p>
      <p>
        We, CareerBuddy, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update
        this page. Any changes to these Terms of Service will take effect immediately from the date of publication.
      </p>
      <p>These Terms of Service were last updated on 9 November 2023.</p>
      <h6>Limitations of Use</h6>
      <p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</p>
      <ul class="mb-2">
        <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
        <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
        <li>transfer the materials to another person or "mirror" the materials on any other server;</li>
        <li>
          knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other
          service CareerBuddy provides;
        </li>
        <li>
          use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;
        </li>
        <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
        <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
        <li>harvest, collect, or gather user data without the user’s consent; or</li>
        <li>
          use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of
          third parties
        </li>
      </ul>
      <h6>Intellectual Property</h6>
      <p>
        The intellectual property in the materials contained in this website are owned by or licensed to CareerBuddy and are protected by applicable
        copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.
      </p>
      <p>
        This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these
        restrictions or the Terms of Service, and may be terminated by CareerBuddy at any time.
      </p>
      <h6>Liability</h6>
      <p>
        Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, CareerBuddy makes no
        warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.
      </p>
      <p>
        In no event shall CareerBuddy or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising
        from the use or inability to use this website or the materials on this website, even if CareerBuddy or an authorized representative has been
        notified, orally or in writing, of the possibility of such damage.
      </p>
      <p>
        In the context of this agreement, "consequential loss" includes any consequential loss, indirect loss, real or anticipated loss of profit,
        loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use
        and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.
      </p>
      <p>
        Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.
      </p>
      <h6>Accuracy of Materials</h6>
      <p>
        The materials appearing on our website are not comprehensive and are for general information purposes only. CareerBuddy does not warrant or
        make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise
        relating to such materials or on any resources linked to this website.
      </p>
      <h6>Links</h6>
      <p>
        CareerBuddy has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The
        inclusion of any link does not imply endorsement, approval or control by CareerBuddy of the site. Use of any such linked site is at your own
        risk and we strongly advise you make your own investigations with respect to the suitability of those sites.
      </p>
      <h6>Right to Terminate</h6>
      <p>
        We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any
        breach of these Terms of Service.
      </p>
      <h6>Severance</h6>
      <p>
        Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or
        unenforceable. The validity of the remainder of these Terms of Service is not affected.
      </p>
      <h6>Governing Law</h6>
      <p>
        These Terms of Service are governed by and construed in accordance with the laws of Delaware. You irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
      </p>
    </section>
    <section>
      <h3>CareerBuddy Acceptable Use Policy</h3>
      <p>
        This acceptable use policy covers the products, services, and technologies (collectively referred to as the "Products") provided by
        CareerBuddy under any ongoing agreement. It’s designed to protect us, our customers and the general Internet community from unethical,
        irresponsible and illegal activity.
      </p>
      <p>
        CareerBuddy customers found engaging in activities prohibited by this acceptable use policy can be liable for service suspension and account
        termination. In extreme cases, we may be legally obliged to report such customers to the relevant authorities.
      </p>
      <p>This policy was last reviewed on 9 November 2023.</p>
      <h6>Fair use</h6>
      <p>
        We provide our facilities with the assumption your use will be "business as usual", as per our offer schedule. If your use is considered to be
        excessive, then additional fees may be charged or capacity may be restricted.
      </p>
      <p>
        We are opposed to all forms of abuse, discrimination, rights infringement and/or any action that harms or disadvantages any group, individual
        or resource. We expect our customers and, where applicable, their users ("end-users") to likewise engage our Products with similar intent.
      </p>
      <h6>Customer accountability</h6>
      <p>
        We regard our customers as being responsible for their own actions as well as for the actions of anyone using our Products with the customer’s
        permission. This responsibility also applies to anyone using our Products on an unauthorized basis as a result of the customer’s failure to
        put in place reasonable security measures.
      </p>
      <p>
        By accepting Products from us, our customers agree to ensure adherence to this policy on behalf of anyone using the Products as their end
        users. Complaints regarding the actions of customers or their end-users will be forwarded to the nominated contact for the account in
        question.
      </p>
      <p>
        If a customer — or their end-user or anyone using our Products as a result of the customer — violates our acceptable use policy, we reserve
        the right to terminate any Products associated with the offending account or the account itself or take any remedial or preventative action we
        deem appropriate without notice. To the extent permitted by law, no credit will be available for interruptions of service resulting from any
        violation of our acceptable use policy.
      </p>
      <h6>Prohibited activity</h6>
      <p>
        Copyright infringement and access to unauthorized material Our Products must not be used to transmit, distribute or store any material in
        violation of any applicable law. This includes but isn’t limited to:
      </p>
      <p>
        any material protected by copyright, trademark, trade secret or other intellectual property right used without proper authorization, and any
        material that is obscene, defamatory, constitutes an illegal threat or violates export control laws.
      </p>
      <p>
        The customer is solely responsible for all material they input, upload, disseminate, transmit, create or publish through or on our Products,
        and for obtaining legal permission to use any works included in such material.
      </p>
      <h6>SPAM and unauthorized message activity</h6>
      <p>
        Our Products must not be used for the purpose of sending unsolicited bulk or commercial messages in violation of the laws and regulations
        applicable to your jurisdiction (“spam”). This includes but isn’t limited to sending spam, soliciting customers from spam sent from other
        service providers, and collecting replies to spam sent from other service providers.
      </p>
      <p>
        Our Products must not be used for the purpose of running unconfirmed mailing lists or telephone number lists ("messaging lists"). This
        includes but isn’t limited to subscribing email addresses or telephone numbers to any messaging list without the permission of the email
        address or telephone number owner, and storing any email addresses or telephone numbers subscribed in this way. All messaging lists run on or
        hosted by our Products must be "confirmed opt-in".
      </p>
      <p>Verification of the address or telephone number owner’s express permission must be available for the lifespan of the messaging list.</p>
      <p>
        We prohibit the use of email lists, telephone number lists or databases purchased from third parties intended for spam or unconfirmed
        messaging list purposes on our Products.
      </p>
      <p>
        This spam and unauthorized message activity policy applies to messages sent using our Products, or to messages sent from any network by the
        customer or any person on the customer’s behalf, that directly or indirectly refer the recipient to a site hosted via our Products.
      </p>
      <h6>Unethical, exploitative, and malicious activity</h6>
      <p>
        Our Products must not be used for the purpose of advertising, transmitting or otherwise making available any software, program, product or
        service designed to violate this acceptable use policy, or the acceptable use policy of other service providers. This includes but isn’t
        limited to facilitating the means to send spam and the initiation of network sniffing, pinging, packet spoofing, flooding, mail-bombing and
        denial-of-service attacks.
      </p>
      <p>
        Our Products must not be used to access any account or electronic resource where the group or individual attempting to gain access does not
        own or is not authorized to access the resource (e.g. "hacking", "cracking", "phreaking", etc.).
      </p>
      <p>
        Our Products must not be used for the purpose of intentionally or recklessly introducing viruses or malicious code into our Products and
        systems.
      </p>
      <p>
        Our Products must not be used for purposely engaging in activities designed to harass another group or individual. Our definition of
        harassment includes but is not limited to denial-of-service attacks, hate-speech, advocacy of racial or ethnic intolerance, and any activity
        intended to threaten, abuse, infringe upon the rights of or discriminate against any group or individual.
      </p>
      <p>Other activities considered unethical, exploitative and malicious include:</p>
      <ul class="mb-2">
        <li>
          Obtaining (or attempting to obtain) services from us with the intent to avoid payment; Using our facilities to obtain (or attempt to obtain)
          services from another provider with the intent to avoid payment;
        </li>
        <li>
          The unauthorized access, alteration or destruction (or any attempt thereof) of any information about our customers or end-users, by any
          means or device;
        </li>
        <li>Using our facilities to interfere with the use of our facilities and network by other customers or authorized individuals;</li>
        <li>
          Publishing or transmitting any content of links that incite violence, depict a violent act, depict child pornography or threaten anyone’s
          health and safety;
        </li>
        <li>Any act or omission in violation of consumer protection laws and regulations;</li>
        <li>Any violation of a person’s privacy.</li>
      </ul>
      <p>
        Our Products may not be used by any person or entity, which is involved with or suspected of involvement in activities or causes relating to
        illegal gambling; terrorism; narcotics trafficking; arms trafficking or the proliferation, development, design, manufacture, production,
        stockpiling, or use of nuclear, chemical or biological weapons, weapons of mass destruction, or missiles; in each case including any
        affiliation with others whatsoever who support the above such activities or causes.
      </p>
      <h6>Unauthorized use of CareerBuddy property</h6>
      <p>
        We prohibit the impersonation of CareerBuddy, the representation of a significant business relationship with CareerBuddy, or ownership of any
        CareerBuddy property (including our Products and brand) for the purpose of fraudulently gaining service, custom, patronage or user trust.
      </p>
      <h6>About this policy</h6>
      .
      <p>This policy outlines a non-exclusive list of activities and intent we deem unacceptable and incompatible with our brand.</p>
      <p>
        We reserve the right to modify this policy at any time by publishing the revised version on our website. The revised version will be effective
        from the earlier of:
      </p>
      <p>
        the date the customer uses our Products after we publish the revised version on our website; or 30 days after we publish the revised version
        on our website.
      </p>
    </section>
  </div>
</template>
