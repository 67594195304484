<template>
  <div class="terms">
    <section id="privacy">
      <h3>CareerBuddy Privacy Policy</h3>
      <p>
        Your privacy is important to us. It is CareerBuddy's policy to respect your privacy and comply with any applicable law and regulation
        regarding any personal information we may collect about you, including across our website, https://thecareerbuddy.com/, and other sites we own
        and operate. Personal information is any information about you which can be used to identify you. This includes information about you as a
        person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online
        service.
      </p>
      <p>
        In the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy
        policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect
        and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site.
      </p>
      <p>
        This policy is effective as of 9 November 2023<br />
        Last updated: 9 November 2023
      </p>
      <h6>Information We Collect</h6>
      <p>Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information.</p>
      <p>
        “Voluntarily provided” information refers to any information you knowingly and actively provide us when using or participating in any of our
        services and promotions.
      </p>
      <p>
        “Automatically collected” information refers to any information automatically sent by your devices in the course of accessing our products and
        services.
      </p>
      <h6>Log Data</h6>
      <p>
        When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s
        Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each
        page, and other details about your visit.
      </p>
      <p>
        Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances
        surrounding its t occurrence. This data may include technical details about your device, what you were trying to do when the error happened,
        and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that
        they have occurred, or what the nature of the error is. Please be aware that while this information may not be personally identifying by
        itself, it may be possible to combine it with other data to personally identify individual persons.
      </p>
      <h6>Personal Information</h6>
      <p>
        We may ask for personal information — for example, when you subscribe to our newsletter or when you contact us — which may include one or more
        of the following:
      </p>
      <ul>
        <li>Name</li>
        <li>Email</li>
      </ul>
      <h6>Legitimate Reasons for Processing Your Personal Information</h6>
      <p>
        We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal
        information that is reasonably necessary to provide our services to you.
      </p>
      <h6>Collection and Use of Information</h6>
      <p>We may collect personal information from you when you do any of the following on our website:</p>
      <ul>
        <li>Register for an account</li>
        <li>Sign up to receive updates from us via email or social media channels</li>
        <li>Use a mobile device or web browser to access our content</li>
        <li>Contact us via email, social media, or on any similar technologies</li>
        <li>When you mention us on social media</li>
      </ul>
      <p>
        We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>
      <ul>
        <li>to provide you with our platform's core features and services</li>
        <li>to enable you to customize or personalize your experience of our website</li>
        <li>to contact and communicate with you</li>
        <li>to consider your employment application</li>
        <li>to enable you to access and use our website, associated applications, and associated social media platforms</li>
        <li>for technical assessment, including to operate and improve our app, associated applications, and associated social media platforms</li>
      </ul>
      <p>
        We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from
        other trusted sources. For example, Our marketing and market research activities may uncover data and insights, which we may combine with
        information about how visitors use our site to improve our site and your experience on it.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or comments about this Privacy Policy or your personal information, to make an access or correction request, to
        exercise any applicable rights, to make a complaint, or to obtain information about our policies and practices with respect to any service
        providers outside Canada, our Privacy Officer (or Data Protection Officer) can be reached by mail or email using the following contact
        information: by email at careers@thecareerbuddy.com.
      </p>
      <h6>Security of Your Personal Information</h6>
      <p>
        When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable
        means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
      </p>
      <p>
        Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or
        storage is 100% secure and no one can guarantee absolute data security.
      </p>
      <p>
        You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the
        bounds of our services. For example, ensuring any passwords associated with accessing your personal information and accounts are secure and
        confidential.
      </p>
      <h6>How Long We Keep Your Personal Information</h6>
      <p>
        We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in
        accordance with this privacy policy. For example, if you have provided us with personal information as part of creating an account with us, we
        may retain this information for the duration your account exists on our system. If your personal information is no longer required for this
        purpose, we will delete it or make it anonymous by removing all details that identify you.
      </p>
      <p>
        However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for
        archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.
      </p>
      <h6>Children’s Privacy</h6>
      <p>
        We do not aim any of our products or services directly at children under the age of 13 and we do not knowingly collect personal information
        about children under 13.
      </p>
      <h6>Disclosure of Personal Information to Third Parties</h6>
      <p>We may disclose personal information to:</p>
      <ul class="mb-2">
        <li>a parent, subsidiary or affiliate of our company</li>
        <li>
          third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service
          providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or
          problem-solving providers, marketing providers, professional advisors, and payment systems operators
        </li>
        <li>our employees, contractors, and/or related entities</li>
        <li>our existing or potential agents or business partners</li>
        <li>
          credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have
          provided to you
        </li>
        <li>
          courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective
          legal proceedings, or in order to establish, exercise, or defend our legal rights
        </li>
        <li>
          third parties, including agents or sub-contractors who assist us in providing information, products, services, or direct marketing to you
        </li>
        <li>third parties to collect and process data</li>
        <li>an entity that buys, or to which we transfer all or substantially all of our assets and business</li>
      </ul>
      <h6>Third parties we currently use include:</h6>
      <ul>
        <li>Google Analytics</li>
        <li>Kissmetrics</li>
        <li>SendFox</li>
      </ul>
      <h6>International Transfers of Personal Information</h6>
      <p>
        The personal information we collect is stored and/or processed in United States, or where we or our partners, affiliates, and third-party
        providers maintain facilities.
      </p>
      <p>
        The countries to which we store, process, or transfer your personal information may not have the same data protection laws as the country in
        which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will
        perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information
        in accordance with this privacy policy.
      </p>
      <h6>Your Rights and Controlling Your Personal Information</h6>
      <p>
        Your choice: By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in
        accordance with this privacy policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of
        our website or the products and/or services offered on or through it.
      </p>
      <p>
        Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this privacy
        policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s
        consent to provide the personal information to us.
      </p>
      <p>
        Marketing permission: If you have previously agreed to us using your personal information for direct marketing purposes, you may change your
        mind at any time by contacting us using the details below.
      </p>
      <p>Access: You may request details of the personal information that we hold about you.</p>
      <p>
        Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please
        contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate,
        incomplete, misleading, or out of date.
      </p>
      <p>
        Non-discrimination: We will not discriminate against you for exercising any of your rights over your personal information. Unless your
        personal information is required to provide you with a particular service or offer (for example providing user support), we will not deny you
        goods or services and/or charge you different prices or rates for goods or services, including through granting discounts or other benefits,
        or imposing penalties, or provide you with a different level or quality of goods or services.
      </p>
      <p>
        Downloading of Personal Information: We provide a means for you to download the personal information you have shared through our site. Please
        contact us for more information. Notification of data breaches: We will comply with laws applicable to us in respect of any data breach.
      </p>
      <p>
        Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the
        details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in
        writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to
        contact a regulatory body or data protection authority in relation to your complaint.
      </p>
      <p>
        Unsubscribe: To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using
        the details provided in this privacy policy, or opt-out using the opt-out facilities provided in the communication. We may need to request
        specific information from you to help us confirm your identity.
      </p>
      <h6>Use of Cookies</h6>
      <p>
        We use "cookies" to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores
        on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on
        preferences you have specified.
      </p>
      <p>Please refer to our Cookie Policy for more information.</p>
      <h6>Business Transfers</h6>
      <p>
        If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data, including
        your personal information, among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that
        any parties who acquire us may, to the extent permitted by applicable law, continue to use your personal information according to this policy,
        which they will be required to assume as it is the basis for any ownership or use rights we have over such information.
      </p>
      <h6>Limits of Our Policy</h6>
      <p>
        Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of
        those sites, and cannot accept responsibility or liability for their respective privacy practices.
      </p>
      <h6>Changes to This Policy</h6>
      <p>
        At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative
        or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing
        this privacy policy.
      </p>
      <p>
        If the changes are significant, or if required by applicable law, we will contact you (based on your selected preferences for communications
        from us) and all our registered users with the new details and links to the updated or changed policy.
      </p>
      <p>
        If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your
        personal information.
      </p>
      <h6>Additional Disclosures for General Data Protection Regulation (GDPR) Compliance (EU) Data Controller / Data Processor</h6>
      <p>
        The GDPR distinguishes between organisations that process personal information for their own purposes (known as "data controllers") and
        organisations that process personal information on behalf of other organisations (known as "data processors"). We, CareerBuddy, located at the
        address provided in our Contact Us section, are a Data Controller with respect to the personal information you provide to us.
      </p>
      <h6>Legal Bases for Processing Your Personal Information</h6>
      <p>
        We will only collect and use your personal information when we have a legal right to do so. In which case, we will collect and use your
        personal information lawfully, fairly, and in a transparent manner. If we seek your consent to process your personal information, and you are
        under 16 years of age, we will seek your parent or legal guardian’s consent to process your personal information for that specific purpose.
      </p>
      <p>
        Our lawful bases depend on the services you use and how you use them. This means we only collect and use your information on the following
        grounds:
      </p>
      <p>Consent From You</p>
      <p>
        Where you give us consent to collect and use your personal information for a specific purpose. You may withdraw your consent at any time using
        the facilities we provide; however this will not affect any use of your information that has already taken place. You may consent to providing
        your email address for the purpose of receiving marketing emails from us. While you may unsubscribe at any time, we cannot recall any email we
        have already sent. If you have any further enquiries about how to withdraw your consent, please feel free to enquire using the details
        provided in the Contact Us section of this privacy policy.
      </p>
      <p>Performance of a Contract or Transaction</p>
      <p>
        Where you have entered into a contract or transaction with us, or in order to take preparatory steps prior to our entering into a contract or
        transaction with you. For example, if you contact us with an enquiry, we may require personal information such as your name and contact
        details in order to respond.
      </p>
      <p>Our Legitimate Interests</p>
      <p>
        Where we assess it is necessary for our legitimate interests, such as for us to provide, operate, improve and communicate our services. We
        consider our legitimate interests to include research and development, understanding our audience, marketing and promoting our services,
        measures taken to operate our services efficiently, marketing analysis, and measures taken to protect our legal rights and interests.
      </p>
      <p>Compliance with Law</p>
      <p>
        In some cases, we may have a legal obligation to use or keep your personal information. Such cases may include (but are not limited to) court
        orders, criminal investigations, government requests, and regulatory obligations. If you have any further enquiries about how we retain
        personal information in order to comply with the law, please feel free to enquire using the details provided in the Contact Us section of this
        privacy policy.
      </p>
      <p>International Transfers Outside of the European Economic Area (EEA)</p>
      <p>
        We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will
        be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use
        of binding corporate rules or other legally accepted means.
      </p>
      <p>Your Rights and Controlling Your Personal Information</p>
      <p>
        Restrict: You have the right to request that we restrict the processing of your personal information if (i) you are concerned about the
        accuracy of your personal information; (ii) you believe your personal information has been unlawfully processed; (iii) you need us to maintain
        the personal information solely for the purpose of a legal claim; or (iv) we are in the process of considering your objection in relation to
        processing on the basis of legitimate interests.
      </p>
      <p>
        Objecting to processing: You have the right to object to processing of your personal information that is based on our legitimate interests or
        public interest. If this is done, we must provide compelling legitimate grounds for the processing which overrides your interests, rights, and
        freedoms, in order to proceed with the processing of your personal information.
      </p>
      <p>
        Data portability: You may have the right to request a copy of the personal information we hold about you. Where possible, we will provide this
        information in CSV format or other easily readable machine format. You may also have the right to request that we transfer this personal
        information to a third party.
      </p>
      <p>
        Deletion: You may have a right to request that we delete the personal information we hold about you at any time, and we will take reasonable
        steps to delete your personal information from our current records. If you ask us to delete your personal information, we will let you know
        how the deletion affects your use of our website or products and services. There may be exceptions to this right for specific legal reasons
        which, if applicable, we will set out for you in response to your request. If you terminate or delete your account, we will delete your
        personal information within 21 days of the deletion of your account. Please be aware that search engines and similar third parties may still
        retain copies of your personal information that has been made public at least once, like certain profile information and public comments, even
        after you have deleted the information from our services or deactivated your account.
      </p>
      <p>Additional Disclosures for U.S. States Privacy Law Compliance.</p>
      <p>
        The following section includes provisions that comply with the privacy laws of these states (California, Colorado, Delaware, Florida,
        Virginia, and Utah) and is applicable only to the residents of those states. Specific references to a particular state (in a heading or in the
        text) are only a reference to that state's law and applies only to that state's residents. Non-state specific language applies to all of the
        states listed above.
      </p>
      <h6>Do Not Track</h6>
      <p>
        Some browsers have a "Do Not Track" feature that lets you tell websites that you do not want to have your online activities tracked. At this
        time, we do not respond to browser "Do Not Track" signals.
      </p>
      <p>
        We adhere to the standards outlined in this privacy policy, ensuring we collect and process personal information lawfully, fairly,
        transparently, and with legitimate, legal reasons for doing so.
      </p>
      <h6 id="cookies">Cookies and Pixels</h6>
      <p>
        At all times, you may decline cookies from our site if your browser permits. Most browsers allow you to activate settings on your browser to
        refuse the setting of all or some cookies. Accordingly, your ability to limit cookies is based only on your browser’s capabilities. Please
        refer to the Cookies section of this privacy policy for more information.
      </p>
      <h6>California Privacy Laws - CPPA</h6>
      <p>
        Under California Civil Code Section 1798.83, if you live in California and your business relationship with us is mainly for personal, family,
        or household purposes, you may ask us about the information we release to other organizations for their marketing purposes. In accordance with
        your right to non-discrimination, we may offer you certain financial incentives permitted by the California Consumer Privacy Act, and the
        California Privacy Rights Act (collectively, CCPA) that can result in different prices, rates, or quality levels for the goods or services we
        provide. Any CCPA-permitted financial incentive we offer will reasonably relate to the value of your personal information, and we will provide
        written terms that describe clearly the nature of such an offer. Participation in a financial incentive program requires your prior opt-in
        consent, which you may revoke at any time.
      </p>
      <p>
        Under California Civil Code Section 1798.83, if you live in California and your business relationship with us is mainly for personal, family,
        or household purposes, you may ask us about the information we release to other organizations for their marketing purposes. To make such a
        request, please contact us using the details provided in this privacy policy with “Request for California privacy information” in the subject
        line. You may make this type of request once every calendar year. We will email you a list of categories of personal information we revealed
        to other organisations for their marketing purposes in the last calendar year, along with their names and addresses. Not all personal
        information shared in this way is covered by Section 1798.83 of the California Civil Code.
      </p>
      <h6>California Notice of Collection</h6>
      <p>In the past 12 months, we have collected the following categories of personal information enumerated in the CCPA:</p>
      <ul>
        <li>Identifiers, such as name, email address, phone number, account name, IP address, and an ID or number assigned to your account.</li>
        <li>Employment and education data, such as data you provide when you apply for a job with us.</li>
      </ul>
      <p>
        For more information on information we collect, including the sources we receive information from, review the “Information We Collect”
        section. We collect and use these categories of personal information for the business purposes described in the “Collection and Use of
        Information” section, including to provide and manage our Service.
      </p>
      <h6>Right to Know and Delete</h6>
      <p>
        You have rights to delete your personal information we collected and know certain information about our data practices in the preceding 12
        months. In particular, you have the right to request the following from us:
      </p>
      <ul>
        <li>The categories of personal information we have collected about you;</li>
        <li>The categories of sources from which the personal information was collected;</li>
        <li>The categories of personal information about you we disclosed for a business purpose or sold;</li>
        <li>The categories of third parties to whom the personal information was disclosed for a business purpose or sold;</li>
        <li>The business or commercial purpose for collecting or selling the personal information; and</li>
        <li>The specific pieces of personal information we have collected about you.</li>
      </ul>
      <p>To exercise any of these rights, please contact us using the details provided in this privacy policy.</p>
      <h6>Shine the Light</h6>
      <p>
        In addition to the rights discussed above, you have the right to request information from us regarding the manner in which we share certain
        personal information as defined by applicable statute with third parties and affiliates for their own direct marketing purposes.
      </p>
      <p>
        To receive this information, send us a request using the contact details provided in this privacy policy. Requests must include “Privacy
        Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code.
      </p>
      <h6>Additional Disclosures for UK General Data Protection Regulation (UK GDPR) Compliance (UK) Data Controller / Data Processor</h6>
      <p>
        The GDPR distinguishes between organisations that process personal information for their own purposes (known as “data controllers”) and
        organizations that process personal information on behalf of other organizations (known as “data processors”). For the purposes covered by
        this Privacy Policy, we are a Data Controller with respect to the personal information you provide to us and remain compliant with our data
        controller obligations under GDPR.
      </p>
      <h6>Third-Party Provided Content</h6>
      <p>
        We may indirectly collect personal information about you from third-parties who have your permission to share it. For example, if you purchase
        a product or service from a business working with us, and give your permission for us to use your details in order to complete the
        transaction. We may also collect publicly available information about you, such as from any social media and messaging platforms you may use.
        T he availability of this information will depend on both the privacy policies and your own privacy settings on such platforms.
      </p>
      <h6>Additional Disclosure for Collection and Use of Personal Information</h6>
      <p>
        In addition to the aforementioned purposes warranting the collection and use of personal information, we may also conduct marketing and market
        research activities, including how visitors use our site, website improvement opportunities and user experience.
      </p>
      <h6>Personal Information No Longer Required for Our Purposes</h6>
      <p>
        If your personal information is no longer required for our stated purposes, or if you instruct us under your Data Subject Rights, we will
        delete it or make it anonymous by removing all details that identify you (“Anonymisation”). However, if necessary, we may retain your personal
        information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific,
        or historical research purposes or statistical purposes.
      </p>
      <h6>Legal Bases for Processing Your Personal Information</h6>
      <p>
        Data Protection and Privacy Laws permit us to collect and use your personal data on a limited number of grounds.. In which case, we will
        collect and use your personal information lawfully, fairly and in a transparent manner. We never directly market to any person(s) under 18
        years of age.
      </p>
      <p>Our lawful bases depend on the services you use and how you use them. This is a non-exhaustive list of the lawful bases we use:</p>
      <h6>Consent From You</h6>
      <p>
        Where you give us consent to collect and use your personal information for a specific purpose. You may withdraw your consent at any time using
        the facilities we provide; however this will not affect any use of your information that has already taken place. When you contact us, we
        assume your consent based on your positive action of contact, therefore you consent to your name and email address being used so we can
        respond to your enquiry.
      </p>
      <p>
        Where you agree to receive marketing communications from us, we will do so based solely on your indication of consent or until you instruct us
        not to, which you can do at any time. While you may request that we delete your contact details at any time, we cannot recall any email we
        have already sent. If you have any further enquiries about how to withdraw your consent, please feel free to enquire using the details
        provided in the Contact Us section of this privacy policy.
      </p>
      <h6>Performance of a Contract or Transaction</h6>
      <p>
        Where you have entered into a contract or transaction with us, or in order to take preparatory steps prior to our entering into a contract or
        transaction with you. For example, if you contact us with an enquiry, we may require personal information such as your name and contact
        details in order to respond.
      </p>
      <h6>Our Legitimate Interests</h6>
      <p>
        Where we assess it is necessary for our legitimate interests, such as for us to provide, operate, improve and communicate our services. We
        consider our legitimate interests to include research and development, understanding our audience, marketing and promoting our services,
        measures taken to operate our services efficiently, marketing analysis, and measures taken to protect our legal rights and interests.
      </p>
      <h6>Compliance with Law</h6>
      <p>
        In some cases, we may have a legal obligation to use or keep your personal information. Such cases may include (but are not limited to) court
        orders, criminal investigations, government requests, and regulatory obligations. For example, we are required to keep financial records for a
        period of 7 years. If you have any further enquiries about how we retain personal information , in order to comply with the law, please feel
        free to enquire using the details provided in the Contact Us section of this privacy policy.
      </p>
      <h6>International Transfers of Personal Information</h6>
      <p>
        The personal information we collect is stored and/or processed in the United Kingdom by us. Following an adequacy decision by the EU
        Commission, the UK has been granted an essentially equivalent level of protection to that guaranteed under UK GDPR.
      </p>
      <p>
        On some occasions, where we share your data with third parties, they may be based outside of the UK, or the European Economic Area (“EEA”).
        These countries to which we store, process, or transfer your personal information may not have the same data protection laws as the country in
        which you initially provided the information.
      </p>
      <h6>If we transfer your personal information to third parties in other countries:</h6>
      <ul class="mb-2">
        <li>we will perform those transfers in accordance with the requirements of the UK GDPR (Article 45) and Data Protection Act 2018;</li>
        <li>
          we will adopt appropriate safeguards for protecting the transferred data, including in transit, such as standard contractual clauses
          (“SCCs”) or binding corporate rules.
        </li>
      </ul>
      <h6>Your Data Subject Rights</h6>
      <p>
        Right to Restrict Processing: You have the right to request that we restrict the processing of your personal information if (i) you are
        concerned about the accuracy of your personal information; (ii) you believe your personal information has been unlawfully processed; (iii) you
        need us to maintain the personal information solely for the purpose of a legal claim; or (iv) we are in the process of considering your
        objection in relation to processing on the basis of legitimate interests.
      </p>
      <p>
        Right to Object: You have the right to object to processing of your personal information that is based on our legitimate interests or public
        interest. If this is done, we must provide compelling legitimate grounds for the processing which overrides your interests, rights, and
        freedoms, in order to proceed with the processing of your personal information.
      </p>
      <p>Right to be Informed: You have the right to be informed with how your data is collected, processed, shared and stored.</p>
      <p>
        Right of Access: You may request a copy of the personal information that we hold about you at any time by submitting a Data Subject Access
        Request (DSAR). The statutory deadline for fulfilling a DSAR request is 30 calendar days from our receipt of your request.
      </p>
      <p>
        Right to Erasure: In certain circumstances, you can ask for your personal data to be erased from the records held by organisations. However
        this is a qualified right; it is not absolute, and may only apply in certain circumstances.
      </p>
      <h6>When may the right to erasure apply?</h6>
      <p>When the personal data is no longer necessary for the purpose for which it was originally collected or processed for.</p>
      <p>
        If consent was the lawful basis for processing personal data and that consent has been withdrawn. CareerBuddy relies on consent to process
        personal data in very few circumstances. The Company is relying on legitimate interests as a legal basis for processing personal data and an
        individual has exercised the right to object and it has been determined that the Company has no overriding legitimate grounds to refuse that
        request.
      </p>
      <p>
        Personal data are being processed for direct marketing purposes e.g. a person’s name and email address, and the individual objects to that
        processing.
      </p>
      <p>There is legislation that requires that personal data are to be destroyed.</p>
      <p>
        Right to Portability: Individuals have the right to get some of their personal data from an organisation in a way that is accessible and
        machine-readable, for example as a csv file. Associated with this, individuals also have the right to ask an organisation to transfer their
        personal data to another organisation.
      </p>
      <h6>However, the right to portability:</h6>
      <ul>
        <li>only applies to personal data which a person has directly given to CareerBuddy in electronic form; <br />and</li>
        <li>onward transfer will only be available where this is “technically feasible”.</li>
      </ul>
      <p>
        Right to Rectification: If personal data is inaccurate, out of date, or incomplete, individuals have the right to correct, update or complete
        that data. Collectively this is referred to as the right to rectification. Rectification may involve filling the gaps i.e. to have to have
        incomplete personal data completed – although this will depend on the purposes for the processing. This may involve adding a supplementary
        statement to the incomplete data to highlight any inaccuracy or claim thereof.
      </p>
      <p>This right only applies to an individual’s own personal data; a person cannot seek the rectification of another person’s information.</p>
      <p>
        Notification of data breaches: Upon discovery of a data breach, we will investigate the incident and report it to the UK’s data protection
        regulator and yourself, if we deem it appropriate to do so. Complaints: You have the right, at any time, to lodge a complaint with the
        Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however,
        appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance using the details
        below. Please provide us with as much information as you can about the alleged breach. We will promptly investigate your complaint and respond
        to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.
      </p>
      <h6>Enquiries, Reports and Escalation</h6>
      <p>
        To enquire about CareerBuddy's privacy policy, or to report violations of user privacy, you may contact our Data Protection Officer using the
        details in the Contact us section of this privacy policy.
      </p>
      <p>
        If we fail to resolve your concern to your satisfaction, you may also contact the Information Commissioner’s Office (ICO), the UK Data
        Protection regulator:
      </p>
      <address class="flex flex-col">
        <span>Information Commissioner's Office</span>
        <span>Wycliffe House</span>
        <span>Water Lane</span>
        <span>Wilmslow</span>
        <span>Cheshire</span>
        <span>SK9 5AF</span>
      </address>
      <p>
        Tel: 0303 123 1113 (local rate) <br />
        Website: www.ico.org.uk
      </p>
      <h6>Additional Disclosures for Personal Information Protection and Electronic Documents Act (PIPEDA) Compliance (Canada)</h6>
      <p>Additional scope of personal information</p>
      <p>
        In accordance with PIPEDA, we broaden our definition of personal information to include any information about an individual, such as financial
        information, information about your appearance, your views and opinion (such as those expressed online or through a survey), opinions held
        about you by others, and any personal correspondences you may have with us. While this information may not directly identify you, be aware
        that it may be combined with other information to do so.
      </p>
      <p>
        As PIPEDA refers to personal information using the term Personally Identifying Information (PII), any references to personal information and
        PII in this privacy policy, and in official communications from CareerBuddy, are intended as equivalent to one another in every way, shape and
        form.
      </p>
      <h6>Valid Consent</h6>
      <p>
        Where you give us consent to collect and use your personal information for a specific purpose. You may withdraw your consent at any time using
        the facilities we provide; however this will not affect any use of your information that has already taken place. When you contact us, we
        assume your consent based on your positive action of contact, therefore you consent to your name and email address being used so we can
        respond to your enquiry. Under PIPEDA, consent is only valid if it is reasonable to expect that an individual to whom the organization's
        activities are directed would understand the nature, purpose, and consequences of the collection, use, or disclosure of the personal
        information to which they are consenting.
      </p>
      <p>
        Where you agree to receive marketing communications from us, we will do so based solely on your indication of consent or until you instruct us
        not to, which you can do at any time.
      </p>
      <p>
        While you may request that we delete your contact details at any time, we cannot recall any email we have already sent. If you have any
        further enquiries about how to withdraw your consent, please feel free to enquire using the details provided in the Contact Us section of this
        privacy policy.
      </p>
      <h6>International Transfers of Information</h6>
      <p>
        While CareerBuddy endeavors to keep, store and handle customer data within locations in Canada, it may use agents or service providers located
        in the United States (U.S.), European Economic Area (EEA) or United Kingdom (UK) to collect, use, retain and process personal information as
        part of providing services to you. While we use all reasonable efforts to ensure that personal information receives the same level of security
        in any other jurisdiction as it would in Canada, please be aware that privacy protections under U.S. laws may not be the same adequacy.
      </p>
      <h6>Customer Data Rights</h6>
      <p>Although PIPEDA does not contain an extensive set of consumer rights, it does grant consumers the right to:</p>
      <ul class="mb-2">
        <li>Access the personal information organizations hold about them;</li>
        <li>
          Correct any inaccurate or outdated personal information the organization hold about them (or, if this is not possible, delete the inaccurate
          personal information)
        </li>
        <lli> Withdraw consent for any activities for which they have consented (e.g. direct marketing or cookies </lli>
      </ul>
      <h6>Right to Withdraw Consent</h6>
      <p>
        Where you give us consent to collect and use your personal information for a specific purpose. <br />
        Subject to some restrictions, you can, at any time, refuse to consent, or continue to consent to the collection, use or disclosure of their
        personal information by notifying us using the email address below in the ‘Contact Us’ section. Withdrawal of consent may impact our ability
        to provide or continue to provide services.
      </p>
      <p>Customers cannot refuse collection, use and disclosure of their personal information if such information is required to:</p>
      <ul class="mb-2">
        <li>be collected, used or disclosed as required by any law;</li>
        <li>fulfill the terms of any contractual agreement; and</li>
        <li>be collected, used or disclosed as required by any regulators including self regulatory organizations</li>
      </ul>
      <p>
        While you may request that we delete your contact details at any time, we cannot recall any email we have already sent. If you have any
        further enquiries about how to withdraw your consent, please feel free to enquire using the details provided in the Contact Us section of this
        privacy policy.
      </p>
      <h6>Right of Access under PIPEDA</h6>
      <p>
        PIPEDA gives you a general right to access the PII held by businesses subject to this law. Under PIPEDA, you need to make your access request
        in writing and pay a minimal fee of $30.00.<br />
        If any organizational fees seem unjust, you have the right to complain about this. We retain the right to decide how we disclose the copies of
        your PII to you. We will take all necessary measures to fulfill your request in 30 days from receipt, otherwise we must inform you of our
        inability to do so before the 30-day timeframe if:
      </p>
      <p>
        meeting the time limit would unreasonably interfere with our business activities; or the time required to undertake consultations necessary to
        respond to the request would make it impractical to meet the time limit.
      </p>
      <p>
        . We can also extend the time limit for the length of time required to convert the personal information into an alternative format. In these
        circumstances, we will advise you of the delay within the first 30 days and explain the reason for it.
      </p>
      <h6>Right of rectification under PIPEDA</h6>
      <p>
        You may request a correction to any factual errors or omissions within your PII. We would ask you to provide some evidence to back up your
        claim. Under PIPEDA, an organization must amend the information, as required, if you successfully demonstrate that it’s incomplete or
        inaccurate.<br />
        You may contact us at any time, using the information provided in the Contact Us section of this privacy policy if you believe your PII on our
        systems is incorrect or incomplete. If we cannot agree on changing the information, you have the right to have your concerns recorded with the
        Office of the Privacy Commission of Canada.
      </p>
      <h6>Compliance with PIPEDA’s Ten Principles of Privacy</h6>
      <p>This privacy policy complies with the PIPEDA’s requirements and ten principles of privacy, which are as follows:</p>
      <p>
        Accountability. CareerBuddy is responsible for the PII under its control and will designate one or more persons to ensure organizational
        accountability for compliance with the ten principles of privacy under PIPEDA, whose details are included below. All personnel are accountable
        for the protection of customers’ personal information.
      </p>
      <p>
        Identifying purposes. CareerBuddy identifies the purposes for which personal information is collected at or before the time the information is
        collected.
      </p>
      <p>
        Consent. Consent is required for CareerBuddy's collection, use or disclosure of personal information, except where required or permitted by
        PIPEDA or other law. In addition, when customers access a product or service offered by us, consent is deemed to be granted. Express consent
        may be obtained verbally, in writing or through electronic means. Alternatively, consent may be implied through the actions of customers or
        continued use of a product or service following CareerBuddy's notification of changes.
      </p>
      <p>
        Limiting collection. Personal information collected will be limited to that which is necessary for the purposes identified by CareerBuddy.
      </p>
      <p>
        Limiting use, disclosure and retention. We will not use or disclose personal information for purposes other than those for which the
        information was collected, except with your consent or as required by law. We will retain personal information only for as long as is
        necessary to fulfill the purposes for collecting such information and compliance with any legal requirements.
      </p>
      <p>
        Accuracy. Personal information will be maintained by CareerBuddy in an accurate, complete and up-to-date format as is necessary for the
        purpose(s) for which the personal information was collected.
      </p>
      <p>Safeguards. We will protect personal information with security safeguards appropriate to the sensitivity of such information.</p>
      <p>
        Openness. We will make our policies and practices relating to the collection and management of personal information readily available upon
        request, including our brochures or other information that explain our policies, standards, or codes.
      </p>
      <p>
        Customer access. We will inform customers of the existence, use and disclosure of their personal information and will provide access to their
        personal information, subject to any legal restrictions. We may require written requests for access to personal information and in most cases,
        will respond within 30 days of receipt of such requests. Customers may verify the accuracy and completeness of their personal information, and
        may request the personal information be corrected or updated, if appropriate.
      </p>
      <p>
        Challenging compliance Customers are welcome to direct any questions or inquiries concerning our compliance with this privacy policy and
        PIPEDA requirements using the contact information provided in the Contact Us section of this privacy policy.
      </p>
      <h6>Cookie Compliance</h6>
      <p>
        Our email interactions with our customers are compliant with Canadian Anti-Spam Legislation. The Company does not send unsolicited email to
        persons with whom we have no relationship. We will not sell personal information, such as email addresses, to unrelated third-parties. On
        occasion, your personal information may be provided to our third-party partners to administer the products and services you request from us.
      </p>
      <p>
        When you leave our website by linking to another website, you are subject to the privacy and security policies of the new website. We
        encourage you to read the privacy policies of all websites you visit, especially if you share any personal information with them.
      </p>
      <p>Please refer to our Cookie Policy for more information.</p>
      <h6>Enquiries, Reports and Escalation</h6>
      <p>
        To enquire about CareerBuddy's privacy policy, or to report violations of user privacy, you may contact us using the details in the Contact us
        section of this privacy policy.
      </p>
      <p>If we fail to resolve your concern to your satisfaction, you may also contact the Office of the Privacy Commissioner of Canada:</p>
      <address class="flex flex-col">
        <span>30, Victoria Street</span>
        <span>Gatineau, Quebec K1A 1H3</span>
        <span>Toll Free: 1.800.282.1376</span>
        <span>www.priv.gc.ca</span>
      </address>
      <h6 class="mt-3">Contact Us</h6>
      <p>For any questions or concerns regarding your privacy, you may contact us using the following details:</p>
      <p>
        Tara Nwosu<br />
        careers@thecareerbuddy.com
      </p>
    </section>
    <section id="cookie" name="cookie">
      <h3>CareerBuddy Cookie Policy</h3>
      <p>Effective Date: 9 November 2023</p>
      <p>
        We use cookies to help improve your experience of our website at https://thecareerbuddy.com/. This cookie policy is part of CareerBuddy's
        privacy policy. It covers the use of cookies between your device and our site.
      </p>
      <p>
        We also provide basic information on third-party services we may use, who may also use cookies as part of their service. This policy does not
        cover their cookies.
      </p>
      <p>
        If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from https://thecareerbuddy.com/. In such a
        case, we may be unable to provide you with some of your desired content and services.
      </p>
      <h6>What is a cookie?</h6>
      <p>
        A cookie is a small piece of data that a website stores on your device when you visit. It typically contains information about the website
        itself, a unique identifier that allows the site to recognise your web browser when you return, additional data that serves the cookie’s
        purpose, and the lifespan of the cookie itself.
      </p>
      <p>
        Cookies are used to enable certain features (e.g. logging in), track site usage (e.g. analytics), store your user settings (e.g. time zone,
        notification preferences), and to personalize your content (e.g. advertising, language).
      </p>
      <p>
        Cookies set by the website you are visiting are usually referred to as first-party cookies. They typically only track your activity on that
        particular site.
      </p>
      <p>
        Cookies set by other sites and companies (i.e. third parties) are called third-party cookies They can be used to track you on other websites
        that use the same third-party service.
      </p>
      <h6>How Can You Control Our Website's Use of Cookies?</h6>
      <p>
        You have the right to decide whether to accept or reject cookies on our Website. You can manage your cookie preferences in our Cookie Consent
        Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be
        rejected as they are strictly necessary to provide you with the services on our Website.
      </p>
      <p>
        You may also be able to set or amend your cookie preferences by managing your web browser settings. As each web browser is different, please
        consult the instructions provided by your web browser (typically in the "help" section). If you choose to refuse or disable cookies you may
        still use the Website, though some of the functionality of the Website may not be available to you.
      </p>
      <h6>How Often Will We Update This Cookie Policy?</h6>
      ,
      <p>
        We may update this Cookie Policy from time to time in order to reflect any changes to the cookies and related technologies we use, or for
        other operational, legal or regulatory reasons.
      </p>
      <p>
        Each time you use our Website, the current version of the Cookie Policy will apply. When you use our Website, you should check the date of
        this Cookie Policy (which appears at the top of this document) and review any changes since the last version.
      </p>
      <h6>Where Can You Obtain Further Information?</h6>
      <p>For any questions or concerns regarding our Cookie Policy, you may contact us using the following details:</p>
      <p>
        Tara Nwosu <br />
        careers@thecareerbuddy.com
      </p>
    </section>
  </div>
</template>
