import TermsOfUse from '@/components/partials/TermsOfUse.vue';
<template>
  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[85vw] px-[24px] md:px-0">
      <div class="py-[5rem] pb-1">
        <div class="flex justify-between items-end">
          <div>
            <h2 class="text-center mb-8 text-[50px] text-brand-black leading-[110%] font-semibold">Terms and Privacy Policy</h2>
            <p>
              This policy was last updated on <br class="hidden md:inline-block" />
              November 9th, 2023
            </p>

            <div class="mt-[5rem]">
              <ul class="flex items-center">
                <li class="mr-4 px-3 pb-2 cursor-pointer" @click="tab = 0">Terms of service</li>
                <li class="px-3 pb-2 cursor-pointer" @click="tab = 1">Privacy</li>
              </ul>
            </div>
          </div>
          <div>
            <img src="@/assets/images/terms.png" class="w-[400px]" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[85vw] max-w-4xl px-[24px] md:px-0">
      <div class="py-[5rem]">
        <TermsOfUse v-if="tab == 0" />
        <PrivacyPolicy v-else />
      </div>
    </div>
  </div>
</template>

<script setup>
import PrivacyPolicy from '@/components/partials/PrivacyPolicy.vue';
import TermsOfUse from '@/components/partials/TermsOfUse.vue';
import { ref } from 'vue';
import { useMeta } from '@/utils/meta';

const tab = ref(0);

useMeta();
</script>
